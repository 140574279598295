<script>
	import Fa from 'svelte-fa'
	import Dispatch from '../Dispatcher'
	import { faWavePulse, faDesktop, faWindTurbine } from '@fortawesome/pro-solid-svg-icons'
	import { faBars, faGlobePointer } from '@fortawesome/pro-regular-svg-icons'
	import { delay, map, of, tap, animationFrames, merge, timer, from } from 'rxjs'
	import { switchMap, startWith, share, mergeMap, finalize, pairwise, takeWhile, scan, withLatestFrom } from 'rxjs/operators'
	import _ from 'lodash'

	import Arrow from '../assets/Arrow.png'
	import ScavengerGamesLogo from '../assets/SpooktacularLogo.png'
	import LaserBackground2 from '../assets/LaserBackground.mp4'
	import WarningConfined from '../assets/WarningConfined.png'
	import WarningLaser from '../assets/WarningLaser.png'
	import WarningBadVibes from '../assets/WarningBadVibes.png'
	import chamberlogo from '../assets/ChamberLogo.png'
	import JuneWinners from '../assets/AugustPictures/Winners.jpg'
	import SaucedWide from '../assets/AugustPictures/SaucedWide.jpg'
	import ParadeFloat from '../assets/ParadeFloat.jpeg'
	import Fog from '../assets/Fog.png'
	import { onMount } from 'svelte';
	import Items from './Items.svelte'
	import TravelWisconsin from '../assets/TravelWisconsin.png'
	import SaucedNeonLogo from '../assets/Sauced Neon Logo.png'
	import ChamberLogo from '../assets/chamberlogo.svg'
	import DitherLogo from '../assets/DitherLogo.svg'
	import Gallery from './Gallery.svelte'

	import Badges from '../assets/Badges.png'
	import Header from './Header.svelte'

	let email = ''
	let submitting = false
	let error = null

	const initialHeight = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerHeight), 
		)

  	const initialWidth$ = merge(
			of(window),
			Dispatch.getAction('resize')
		)
		.pipe(
			map(e => e.innerWidth > 550 ? (550 - 85) : e.innerWidth - 85), 
		)

		let headerHeight = 0;

		onMount(() => {
			requestAnimationFrame(() => {
				const header = document.querySelector('#header');
				headerHeight = header.offsetHeight;
			});
		});

		Dispatch.getAction('createLead')
			.pipe(
				tap(e => { submitting = true }),
				tap(e => window?.gtag('event', 'contact', {})),
				tap(e =>  window?.gtag('event', 'Contacted', {})),
				mergeMap(e => from(fetch(import.meta.env.VITE_DEV_SERVER + '/createLead', {
						method: 'POST',
						headers: {
							'content-type': 'application/json'
						},
						body: JSON.stringify({
							email: email,
						})
					}))
					.pipe(
						mergeMap(e => e.json())
					)
				),
				tap(e => { submitting = false }),
				tap(e => { email = '' })
			)
			.subscribe(e => {
				if (!e) {
					error = 'Failure to submit. Please try again.'
				}
			})

	const clock$ = animationFrames()
		.pipe(
		)

	const x$ = clock$
			.pipe(
				withLatestFrom(initialWidth$),
				scan((acc,[next, width]) => {
					let newAcc = null
					let newForward = acc.forward

					if (acc.forward) {
						newAcc = acc.distance + (width/1000)
					}
					else {
						newAcc = acc.distance - (width/1000)
					}

					if (acc.forward && acc.distance > 0) {
						newForward = false
					}
					if (!acc.forward && acc.distance < -(width/2)) {
						newForward = true
					}


					return {
						forward: newForward,
						distance: newAcc
					}
				}, {
					forward: true,
					distance: 0,
				}),
			)
		

</script>

	<!-- Parent of Everything-->
	<div class='w-100 dib'>
		<!-- <Background /> -->
		<div class='fixed w-100 vh-100 bg-black' style='z-index:-1;'>
			<div class='flex w-100 justify-center'>
				<video class='mw8 w-100 h-100' style='object-fit: cover;' src={LaserBackground2} autoPlay loop muted playsInline></video>
				<div class='mw8 block'></div>
			</div>
		</div>
		<!-- style='background: linear-gradient(#0D0101,#2C2C2C); -->
		<div id='header' class='w-100 bb' style='position: sticky; top: 0px; z-index:100000000000'>
			<Header />
		</div>
			<div class='w-100 avenir flex flex-column items-center'>
			<!-- <div class='absolute w-100 h-100' style={'background-image: url("media/Background.jpeg"); background-size: cover, cover; background-repeat: no-repeat; opacity: .08'} /> -->
				<div class='w-100 flex flex-column items-center justify-between' style={'left:0px; top:0px; height: ' + ($initialHeight - 10 - headerHeight) +'px'}>


						<!-- <div class='pv3 bg-red white w-100 tc fw7 underline f5 f4-ns' on:click={e => document.getElementById('activationcode').scrollIntoView()}>Memorial Day Activation Button</div> -->
						
						<!-- <div class='h-100 w-100'><Signup/></div> -->
						<div class='w-100 bg-black happy-yellow pv1 pv2-ns fw7 f4 bb b--dark-gray tc'><h1 class='fw7 f4'>The Ultimate Scavenger Hunt</h1></div>

						<div class='w-100 absolute' style={'overflow:hidden; pointer-events: none;'}>
							<div class='relative' style={'left: '+ $x$?.distance + 'px; width: 150%;'}>
								<img class="w-100" style={''}  src={Fog} />
							</div>
						</div>
						
						<div class='pt3 w-100'></div>
						
						<img class='' alt='East Troy Scavenger Games Logo' src={ScavengerGamesLogo} style='z-index: 1000; max-height: 60%; max-width: 90%; min-width:0px; min-height:0px; width: auto; height: auto'/>
							

						<div class='pt3'></div>
						
						<div class='w-100 flex justify-center ph1 pb3 pb5-ns f3 f2-ns fw8 fw5-ns tc avenir' >
							<!-- <Story /> -->
							<div class='w-100 mw6 mh4 flex flex-column pv3 bg-happy-yellow br4 black fw7 f4 f2-ns'>
								<!-- <div class='f2 fw8'>$1000 PRIZE <br/><span class='f3'>SCAVENGER HUNT</span></div> -->
								<div class='f4'>Last Chance</div>
								<div class='f2 fw8'>$1000 PRIZE</div>
								<!-- <div class='f4'>Ultimate Scavenger Hunt</div> -->
								<div class='w-100 ph3'><div class='w-100 mt2-ns black bg-black' style='height:2px'></div></div>
								<div class='pt2 pt3-ns f4'>Get the latest updates! </div>
								<!-- <div class='di mt3 mh3 mh4-ns pv3 ph2 f4 fw7 bg-black white br3' on:click={e => document.getElementById('tickets').scrollIntoView()}>Get your Tickets now!</div> -->
								<!-- <div class='di mt3 mh3 mh4-ns pv3 ph2 f4 fw7 bg-black white br3' on:click={e => document.getElementById('tickets').scrollIntoView()}>Get updates!  -->
								<!-- <div class='w-100 ph3'><div class='w-100 mt3 black bg-black' style='height:2px'></div></div> -->
								<div class='w-100 pt3 flex'>
									<div class='w-100 pl3 pr2'><input class='w-100 pv3 ph3 br3 bn f5 f4-ns bg-black yellow fw7' type="email" name="email" required placeholder="Email" bind:value={email}></div>
									<div class='pr3'><div class='pr3 pl2 pv3 ph3 f5 f4-ns fw7 bg-blue white br3 bn pointer' on:click={e => { Dispatch.nextAction('createLead')} }>{submitting ? '...' : 'Go'}</div></div>
								  {#if !_.isNil(error)}
										<div class='pt3 red '>{error}</div>
									{/if}
								</div>

							</div>

							<!-- <div class='w-100 mw6 mh4 flex flex-column items-center pv3 bg-happy-yellow br4 black fw7 f4 f2-ns'>
								<div class='f2 fw8'>$1000 PRIZE</div>
								<div class='f4'>August 17th </div>
								<div class='w-100 mw5 ph3'><div class='w-100 mt2 black bg-black' style='height:2px'></div></div>
								<div class='w-100 flex items-center justify-center'>
									<div class='mt2 ph3 pv3 bg-blue white br4 f4 fw7' on:click={e => Dispatch.nextAction('navigate', {page: 'tickets'})}>Create Your Team</div>
								</div>
							</div> -->

							</div>
						</div>
				</div>
				<!-- <div class='w-100 mw8 flex flex-column items-center bg-happy-yellow'>
					<div class='w-100 mw6 pt4 f1 fw8 tc'>LASERS <br/> Discover <br/> Win!</div>
					<div class='pv5'></div>
				</div>
				<div class='w-100 mw8 flex flex-column items-center bg-happy-yellow'>
					<div class='w-100 mw6 pt4 f1 fw7 tc'>Explore <br/> Discover <br/> Win!</div>
					<div class='pv5'></div>
				</div> -->
				<div class='w-100 pt5 flex flex-column items-center'>
					<!-- <div class='w-100 pt4 bt b--yellow bw4 flex flex-column items-center bg-white'>
						<div class=''>In partnership with</div>
						<div class='w-100 ph4 pt4'><img src={chamberlogo} /></div>
					</div> -->
					<div class='w-100 mw8 bt bb b--happy-yellow bw4' style='line-height: 0; font-size: 0;'>
						<img class='w-100' alt='The winners of the scavenger games holding a large check' src={JuneWinners} />
					</div>
					<div class='w-100 mw8 tc happy-yellow pt3 fw7 bg-black tl-ns'>August $1000 Winners</div>
					<div id='intro' class='w-100 mw8  b--happy-yellow bw4 pb3 pb4-ns ph3 ph5-ns bg-black yellow flex flex-column items-center '>
						<!-- <img class='pt3 w-80' src={LaserMazeCutout} /> -->

							<!-- <div class='w-100 absolute' style='z-index: -1;'>
								<img class='' src={LaserBackgroundImage} style='z-index:1; max-width:100%; max-height:100%; width: auto; height: auto'/>
							</div> -->
							<div class='relative pt6 w-100 flex justify-center' style='z-index:10; transform: rotate(5deg);'>
								<div class='bg-happy-yellow ph4 pv3 f1 black fw8 br3'>LASERS</div>
								<div></div>
							</div>
							<div class='relative pt4 w-100 flex justify-center' style='z-index:10; transform: rotate(-5deg);'>
								<div class='bg-happy-yellow ph4 pv3 f1 black fw8 br3'>MAZES</div>
								<div></div>
							</div>
							<div class='relative pt4 w-100 flex justify-center' style='z-index:10;'>
								<div class='bg-happy-yellow ph4 pv3 f1 black fw8 br3'>AND MORE</div>
							</div>
							<div class='relative w-100 pb4 pb0-ns flex justify-between pt1 justify-center' style='z-index:10;'>
								<!-- <div></div> -->
								<div class='dib dn-ns absolute' style='right:70px'>
										<img class='w4' src={Arrow} style='right: 0px'/>
								</div>
								<div class='dn flex-ns w-100 justify-center' style='right:70px'>
									<img class='relative w4' src={Arrow} style='left: 100px'/>
								</div>
							</div>

						<div class='flex flex-column pt6 pt3-ns ph4 items-center justify-center '>
							
							<div class='mw6 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>THE ULTIMATE ADVENTURE</div> 
							<!-- <img class='pt5 w-100' src={Adventure} style='right: 0px'/> -->
							<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
								The Scavenger Games is a city wide, escape room style, scavenger hunt. 
								Whether you’re an adult, family, child, CEO, co-worker, influencer, educator or grandparent ... this “behind-the-scenes” East Troy adventure will have something for you!
								<br/> <br/>Face off against other teams in a game of wits, strategy, diplomacy, physical feats, and cleverness for the ultimate prize of $1,000 while experiencing southeastern Wisconsin’s coolest place like never before. 
							</div>
							<div class='w-100 pt4 pt5-ns flex flex-column items-center'>
								<iframe class='br4 bw3 b--yellow' width={$initialWidth$} height={ $initialWidth$ * 1.7777777777 } src="https://youtube.com/embed/GzSFMiD53PM?si=2OMN-jOsoxRWH_T5" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
							</div>
							<div class='pt5 fw8 f1 tl tc-ns happy-yellow ' style='z-index:1;'>Spook-tacular Bash</div> 
							<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
								Spectators are an important part of the games and can join the action with the Opening Ceremony, 
								live music by Big Al Wetzel Band from 5:30 to 8:30 p.m., beer tent, food trucks and 
								Spook-tacular family-friendly fun at East Troy Village Square Park.						
							</div>
							<div class='mt4 pv2 ph3 bg-blue br3 white fw6 f3'><a class='link white' href="https://easttroy.org/events/2024/oct/19/scavenger-games-bash/">Click for Details</div>
							<div class='pt5 fw8 f1 tl tc-ns happy-yellow ' style='z-index:1;'>Key Features</div> 
							<div class='w-100 mw6'></div>
							<div class='w-100 pt5 f2 fw7 tl happy-yellow'>Exclusive Experiences</div>
							<div class='pt3 f4 fw5 tj white mw6' style='line-height:35px'>
								From uncovering secrets of the historic East Troy Railroad Museum to a Hill Valley Dairy Cheese Curd Challenge at East Troy Brewery to the Sauced Pizzeria laser maze, each location becomes a thrilling part of the game.
							</div>
							<div class='w-100 pt4 f2 fw7 tl happy-yellow'>Crazy Interactivity</div>
							<div class='pt3 f4 fw5 tj white mw6' style='line-height:35px'>
								Whether through technology, shop owners, or the environment, you'll be interacting with the world around you in ways you never thought possible.
							</div>
							<div class='w-100 pt4 f2 fw7 tl happy-yellow'>Awesome People</div>
							<div class='pt3 f4 fw5 tj white mw6' style='line-height:35px'>
								Meet new people, make new friends, and have a blast with your team as you work together to solve puzzles, and complete challenges...
								<br/> <br/> But beware, for certain challenges you may need your competitors to become your friends!
							</div>

							<div class='pt5 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>Gallery</div>
							<Gallery />
							<!-- <div class='pt4 tc'>$1000 prize for the winner :</div>
							<div>June 15, Aug. 17 & Oct. 19</div> -->
							<!-- <div class='pt5 tc f1 fw7' style='line-height:45px'>2024 <br/> Scavenger Dates</div>
							<div class='pt2 tc f4'>(separate prizes for each)</div> -->
							<!-- <div class='pt4 f2 fw7 white '>June 15</div> -->
							<!-- <div class='pt2 f2 fw7 white '>August 17</div>
							<div class='pt2 f2 fw7 white'>October 19</div> -->
							<!-- <div class='pt5 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>SCAVENGE AWAY</div> 
							<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
								
								Choose your team wisely. Every member matters. From puzzle solving, to data gathering, to physical abilities, you'll want a little of everything as you face off in the ultimate scavenger hunt!
							</div> -->
							<!-- <div class='pt5 w-100 flex justify-center'><img class='w-100 mw6 ba b--happy-yellow bw3 br3' src={WarningLaser} /></div> -->
							<!-- <img class='pt5 w-100 mw6' src={Adventure} style='right: 0px'/>
								<div class='pt5 fw8 f1 tl happy-yellow' style='z-index:1;'>YESSSSSS, MORE PLZ</div>
							<div class='pt4 f4 fw5 tc white mw6' style='line-height:35px'>
								
								Choose your team wisely. Every member will matter. From puzzle solving, to data gathering, to physical abilities, you'll want a little of everything as you face off against blank players in the ultimate scavenger hunt game to win $1000 prize! </div>
							</div> -->
							</div>
						</div>

						<div class='w-100 pt5 mw8 bb b--happy-yellow bw4 bg-black'>
							<img alt='The Scavenger Games parade float' src={ParadeFloat} />
						</div>
				</div>
				
				<div class='vh-50'></div>
				<!-- <div class='w-100 mw8 flex flex-column items-center'>
					<div class='relative w-100 bt bw3 b--black pb3 pb4-ns ph3 ph5-ns bg-happy-yellow black flex flex-column items-center '>
						<div class='relative pt6 w-100 flex justify-center' style='z-index:10; transform: rotate(5deg);'>
							<div class='bg-black happy-yellow ph4 pv3 f1  fw8 br3'>HOW IT</div>
							<div></div>
						</div>
						<div class='relative pt4 w-100 flex justify-center' style='z-index:10; transform: rotate(-5deg);'>
							<div class='bg-black happy-yellow ph4 pv3 f1  fw8 br3'>WORKS!</div>
							<div></div>
						</div>
						<div class='pt4'>By being awesome</div>
					</div>
				</div> -->
				<!-- <div class='w-100 flex justify-center'>
					<div id='activationcode' class='w-100 pb5 flex flex-column bg-red white bt bb b--white bw4 mw8'>
						<div class='ph4 pt5 fw7 f1 tc happy-yellow'>Activation Button</div>
						<div class='ph4 pt3 fw7 f4 tc'>(Memorial Weekend)</div>
						<div class='pt4 ph4 f4 fw5 tj tc-ns white' style='line-height:35px'>If you're on the East Troy Square this memorial day use the following button to start the process of activating the Bubble Machines. </div> 
						<div class='mt5 vh-50'style="position: relative; padding-bottom: 56.25%; max-height: 500px; overflow: hidden;">
							<iframe src="https://www.easttroy.show/?client=memorial" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; border: 0;" allowfullscreen></iframe>
						</div>
						<div class='pt4 ph4 f4 fw5 tj tc-ns white' style='line-height:35px'>After tapping the activate button you'll have 40 seconds to spin the generator to give the bubble machines power. After that time, the system will need to cooldown for 1 minute before you can activate it again. Good luck!</div>
					</div>
				</div> -->
				<div class='w-100 pt5 flex flex-column items-center'>
					<div class='w-100 mw8 flex flex-column items-center'>
						<div id='tickets' class='relative w-100 ph3 bt pb4 bw5 b--black bg-happy-yellow black flex flex-column items-center '>
							<div class='relative pt6 w-100 flex justify-center' style='z-index:10; transform: rotate(5deg);'>
								<div class='bg-black happy-yellow ph4 pv3 f1  fw8 br3'>LAST</div>
								<div></div>
							</div>
							<div class='relative pt4 w-100 flex justify-center' style='z-index:10; transform: rotate(-5deg);'>
								<div class='bg-black happy-yellow ph4 pv3 f1  fw8 br3'>CHANCE</div>
								<div></div>
							</div>
							<div class='relative pt4 w-100 flex justify-center' style='z-index:10;'>
								<div class='bg-black happy-yellow ph4 pv3 f1  fw8 br3'>TO PLAY</div>
								<div></div>
							</div>
							
							<div class='w-100 mw6'>
								<div class='pt5 pt6-ns ph4 f2 f1-ns fw5 tc black' style='line-height:35px'>
										
									<div class='fw7'>Win <b>$1000!</b> </div>
									<div class='pt3 fw7'>October 19th </div>

								</div>
								<div class='pt4 ph4 f4 fw5 tj black' style='line-height:35px'>
										
									Gather your team and get ready to win the ultimate prize of $1000. Teams consist of up to 6 players, but you'll need at least 3 to accomplish certain tasks! 

								</div>
								<div class='pt4 ph4 f4 fw7 tj black' style='line-height:35px'>
									<div>Sold Out!</div>

								</div>
								<div class='pt4 ph4 f4 fw5 tj black' style='line-height:35px'>
										
									The third competition of 2024 will be in East Troy, WI on October 19th from 5:30pm-8pm. Good luck!

								</div>
								<!-- <div class='pt5 flex justify-center'>
									<div class='ph4 pv3 br4 bg-blue white fw7 f4 pointer' on:click={e => Dispatch.nextAction('navigate', {page: 'tickets'})}>Create Your Team!</div>
								</div> -->
								<div class='pt4 w-100 flex justify-center'><img alt='A series of badges that can be earned by participating in a game' class='w-100 mw6 ba b--happy-yellow bw2 br2' src={Badges} /></div>
								<!-- <img class='pt5 w-100 mw6' src={ScavengerTicket} style='right: 0px'/> -->
							</div>
						</div>
						<div class='w-100 ph3 pb5 bg-black'>
							<div class='w-100 pt6 tc happy-yellow fw7 f1'>Explore</div>
								<div class='w-100 flex flex-column items-center pt4'>
									<Items />
								</div>
						
						</div>
						<div id='updates' class='w-100 bg-happy-yellow mw8 pt5 pb5 flex flex-column items-center justify-center black ph4 bt bw4'>
							<div class='w-100 mw6 flex justify-center items-center flex-column'>
								<div class='f3 fw7'>Get the latest updates! </div>
								<div class='w-100 ph3'><div class='w-100 mt3 black bg-black' style='height:2px'></div></div>
								<form class='w-100 pt3 flex' style='' action="https://api.web3forms.com/submit" target="dummyframe" method="POST">
									<input type="hidden" name="access_key" value="4a2b270d-a653-4f23-a8bd-9268e5b36213">
									<div class='w-100 pl3'><input class='w-100 pv3 ph3 br3 bn f5 f4-ns bg-black yellow fw7' type="email" name="email" required placeholder="Email"></div>
									<div class='pr3 pl2'><button class=' pv3 ph3 f5 f4-ns fw7 bg-blue white br3 bn' on:click={e => { window?.gtag('event', 'Contacted', {}); setTimeout(function(){window.location.reload();},1000); } } type="submit">Go!</button></div>
			
								</form>
							</div>
						</div>
					</div>
					<div id='faq' class='w-100 mw6 pt6 flex flex-column items-center justify-center happy-yellow ph4'>
						<div class='bg-happy-yellow black br3 ph4 pv3 f1 fw8'>FAQ</div>
						<div class='pt6 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>What are the 2024 Scavenger Games?</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							The Scavenger Games is an interactive, city wide scavenger hunt competition held 3
							times a year. Face off against other teams in a game of wits, strategy, diplomacy,
							physical feats, and cleverness for the ultimate prize of $1,000 while experiencing
							southeastern Wisconsin’s coolest place like never before.
						</div>
						<div class='pt5 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>How many dates are there?</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							The 2024 Scavenger Games: The Ultimate Citywide Adventure will occur on three dates: <br/><br/>
	June 15, 2024: Tickets go on sale May 1. Event time: noon to 3 p.m. <br/> <br/>
	Aug. 17, 2024: Tickets go on sale July 1. Event time: noon to 3 p.m. <br/> <br/>
	Oct. 19, 2024: Tickets go on sale Sept. 1. Event time: 5:30 to 8 p.m. <br/> <br/>
	Each date has its own competition and $1,000 prize. You can choose to compete in all three dates or just one.
						</div>
						<div class='pt5 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>Is there an age limit?</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							No, there is no age limit. This event is family, teen, and tween friendly. Teams with
							members under 18 need to have at least one participant who is 13 years old or older
							and must have parental or guardian approval.
						</div>
						<div class='pt5 fw8 mw6 f1 tl tc-ns happy-yellow' style='z-index:1;'>Who is putting this event on?</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							The 2024 Scavenger Games is hosted by the East Troy Area Chamber of
							Commerce and Happy Vibe Games. We specialize in creating 100% community-built,
							unforgettable experiences that bring people together. From our renowned East Troy
							Lights to the 2024 Scavenger Game to customized interactive events, we blend
							creativity and excitement to create moments you'll never forget.
						</div>
						<div class='pt5 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>Why Join the 2024 Scavenger Games?</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							Exciting Challenges: Test your wits and skills with a variety of challenges designed to
	engage and entertain. From puzzle-solving to physical feats, there's something for
	everyone.
						</div>

						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							Amazing Prizes: Compete for a $1,000 first place prize on each game date!
						</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							Family-Friendly Fun: The Scavenger Games are designed to be enjoyed by
	participants of all ages. Bring the kids, and make it a family outing they'll never forget.
						</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							Live Entertainment: Enjoy live music, food, and entertainment throughout the event.
							It's more than just a scavenger hunt – it&#39;s a community celebration!
						</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							Explore East Troy: Discover the charm of East Troy as you explore its hidden gems
	and local landmarks. You'll see the town in a whole new light!
						</div>
						<div class='pt4 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>How can I sponsor a challenge?</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							Checkout out our <a class='link blue fw7' href='https://form.jotform.com/241570176386158'>Sponsorship Form</a> for sponsorship opportunities. We're always open to new ideas and ways for people to get involved!
						</div>
						<div class='pt5 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>Sponsors</div>
						<div class='pt5'>
							<img class='w-100' src={ChamberLogo} />
						</div>
						<div class='pt3'>
							<img class='w-100' src={TravelWisconsin} />
						</div>
						<div class=''>
							<img class='w-100' src={SaucedNeonLogo} />
						</div>
						<div class=''>
							<img class='w-100' src={DitherLogo} />
						</div>
						<div class='pt5 fw8 f1 tl tc-ns happy-yellow' style='z-index:1;'>How do I participate?</div>
						<div class='w-100 pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							1. Register Your Team: <span class='fw7 blue pointer' on:click={e => Dispatch.nextAction('navigate', {page: 'tickets'})}>Click here</span>
						</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							2. Prepare for Adventure: Gather your team of 3-6 players, plan your strategy, and get
	ready for a day of fun and excitement.
						</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							3. Join the Fun: Arrive at the Opening Games at East Troy Village Square Park by 5:15
							p.m. on event day let the games begin! Competition is 5:30 to 8 p.m. and you must
							compete the entire time to win.
						</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							For any additional questions, contact us at vanessa@easttroy.org.
						</div>
						<div class='pt4 f4 fw5 tj white mw6' style='line-height:35px'>
							Get ready for an unforgettable adventure!
						</div>

					</div>
					<div id='contact' class='w-100 mw8 pt6 pb6 flex flex-column items-center justify-center happy-yellow ph4'>
						<!-- <Contact /> -->
						<div class='bg-happy-yellow black br3 ph4 pv3 f1 fw8'>Contact</div>
						<form class='ph3 pt5 w-100 flex flex-column mw6 justify-center' style='z-index:2000' action="https://api.web3forms.com/submit" method="POST">

							<input type="hidden" name="access_key" value="4a2b270d-a653-4f23-a8bd-9268e5b36213">
					
							<div class='fw8'>Name</div>
							<input class='mt1 pv3 ph3 br3 bn' type="text" name="name" required>
							<div class='pt3 fw8'>Email</div>
							<input class='mt1 pv3 ph3 br3 bn' type="email" name="email" required>
							<div class='pt3 fw8'>Message</div>
							<textarea class='mt1 pv3 ph3 br3 bn' name="message" required></textarea>
							<!-- <div class="pt3 h-captcha" data-captcha="true"></div> -->
							<button class='mt4 button pa3 bg-happy-yellow br3 black fw8 f5 bn' type="submit">Submit</button>
					
						</form>
						
						<script src="https://web3forms.com/client/script.js" async defer></script>
					</div>
									<!-- <div class='w-100 pt4 bt b--yellow bw4 flex flex-column items-center bg-white'>
						<div class=''>In partnership with</div>
						<div class='w-100 ph4 pt4'><img src={chamberlogo} /></div>
					</div> -->
					<div class='w-100 pv3 ph3 w-100 fw5 f6 mw8 bg-happy-yellow black flex flex-column items-center justify-center'>
						<div class='w-100 f7 tc'>© 2024 East Troy Area Chamber of Commerce All Rights Reserved</div>
						<div class='pt2 flex justify-center ph3'>
							<div class=''><a href='/?page=terms' target="_blank">Terms of Service</a></div>
							<div class='pl3'><a href='/?page=privacy' target="_blank">Privacy Policy</a></div>
						</div>
						<!-- <div >Copyright 2023 <a class='link' href='https://dither.studio' >Dither Studio </a></div> -->
					</div>
	</div>
		<iframe name="dummyframe" id="dummyframe" style="display: none;"></iframe>
	</div>

<style>
	::placeholder {
		color: rgb(255, 242, 0);
		opacity: 1; /* Firefox */
	}

	.block {
			width: 100%;
			position: absolute;
			bottom: 0px;
			top: 0px;
			box-shadow: inset 0px 0px 60px 40px black;
			/* box-shadow-color: black;
			box-shadow-offset-x: 0px;
			box-shadow-offset-y: 0px; */
	}

	@media (min-width: 640px) {
		main {
			max-width: none;
		}
	}
</style>